<template>
  <div>
    <Header :headerIndex="7"></Header>
    <div class="container">
      <img class="g_top_banner" src="../assets/static/icon/icon_top_banner7.png" alt="" />
      <div class="blog_wrap">
        <div class="blog_inner max_1440">
          <div class="search_box flex-box">
            <input type="text" @change="searchblog" v-model="key" class="search_input flex-grow-1" placeholder="Please enter the search content" />
            <img class="icon_search" src="../assets/static/icon/icon_search.png" alt="" />
          </div>

          <div class="blog_list flex-box flex-wrap flex-col-start">
            <router-link :to="{path:'/BlogDetail',query:{blogid:item.id}}" class="blog_item" v-for="item in bloglist">
              <div
                class="img"
                :style="{'background-image':'url(' + item.slow_image +')'}"
              ></div>
              <!-- https://images2.img.xilukeji.com/rwf_2023-02-08_63e39c5c5ed69.jpg-rwf_water_rw -->
              <div class="name m-ellipsis-l2">{{item.title}}</div>
              <div class="date">{{item.createtime_text}}</div>
            </router-link>
          </div>

          <div :style="{ display: display }">
            <Pagination :sumCount="total" :now="page" :perCount="per_page" @pageTurn="pageTurn"></Pagination>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import axios from 'axios';
import api from '../api';
export default {
  data() {
    return {
      total: 4, //页面总量
      per_page: 12, //页面限制
      page: 1, //页面页数
      display: "",
      bloglist:[],
      key:''
    };
  },
  created(){
    //初始化加载
    this.getblog()
  },
  methods:{
    //页码
    pageTurn(page) {
      this.page = page + 1
      api.doPost('/api/blog/getblog',{page:this.page,limit:this.per_page},(res)=>{
        if(res.code == 200){
            this.bloglist = res.data
            this.total = res.count
        }
      })
    },
    //获取博客
    getblog(){
      var page = 1
      var limit = this.per_page
      api.doPost('/api/blog/getblog',{page:page,limit:limit},(res)=>{
          if(res.code == 200){
            this.bloglist = res.data
            this.total = res.count
          }
      })
    },
    //搜索blog
    searchblog(){
      // console.log('e',this.key)
      var json = {
        page:this.page,
        limit:this.per_page,
        value:this.key
      }
      api.doPost('/api/index/search',json,(res)=>{
        if(res.code == 200){
            console.log(res.data)
            this.bloglist = res.data
            this.total = res.count
        }else if(res.code == 400){
            this.bloglist = []
            this.total = 1
        }
      })
    }
  }
};
</script>
<style scoped>
.blog_wrap {
  padding: 30px 0;
}

.search_box {
  margin: 0 0 30px;
  padding: 0 20px;
  width: 397px;
  height: 70px;
  background: #333333;
  border-radius: 20px;
}
.search_box .search_input {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
}
.search_box .icon_search {
  margin-left: 10px;
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.blog_list .blog_item {
  margin: 0 30px 30px 0;
  width: calc((100% - 60px) / 3);
}
.blog_list .blog_item:nth-of-type(3n) {
  margin: 0 0 30px;
}
.blog_list .img {
  display: block;
  width: 100%;
  height: 388px;
  border-radius: 20px 20px 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.blog_list .name {
  margin: 10px 0;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 25px;
}
.blog_list .date {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 17px;
}

@media screen and (max-width: 1440px) {
  .blog_wrap {
    padding: 20px 0;
  }

  .search_box {
    margin: 0 0 22px;
    padding: 0 15px;
    width: 298px;
    height: 52px;
    border-radius: 15px;
  }
  .search_box .search_input {
    font-size: 12px;
    line-height: 23px;
  }

  .blog_list .blog_item {
    margin: 0 22px 22px 0;
    width: calc((100% - 44px) / 3);
  }
  .blog_list .blog_item:nth-of-type(3n) {
    margin: 0 0 22px;
  }

  .blog_list .img {
    height: 291px;
  }
  .blog_list .name {
    margin: 8px 0;
    font-size: 14px;
    line-height: 19px;
  }
  .blog_list .date {
    font-size: 10px;
    line-height: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .blog_wrap {
    padding: 30px 125px;
  }

  .search_box {
    margin: 0 0 16px;
    padding: 0 11px;
    width: 240px;
    height: 37px;
    border-radius: 11px;
  }

  .blog_list .blog_item {
    margin: 0 16px 16px 0;
    width: calc((100% - 32px) / 3);
  }
  .blog_list .blog_item:nth-of-type(3n) {
    margin: 0 0 16px;
  }
  .blog_list .img {
    height: 208px;
  }
}
@media screen and (max-width: 768px) {
  .blog_wrap {
    padding: 10px 90px;
  }

  .search_box .icon_search {
    width: 14px;
    height: 14px;
  }

  .blog_list .blog_item {
    margin: 0 12px 12px 0;
    width: calc((100% - 24px) / 3);
  }
  .blog_list .blog_item:nth-of-type(3n) {
    margin: 0 0 12px;
  }
  .blog_list .img {
    height: 155px;
  }
}

@media screen and (max-width: 767px) {
  .blog_wrap {
    padding: 15px;
  }

  .search_box {
    width: 100%;
    height: 37px;
  }

  .blog_list .blog_item {
    margin: 0 15px 15px 0;
    width: calc((100% - 15px) / 2);
  }
  .blog_list .blog_item:nth-of-type(3n) {
    margin: 0 15px 15px 0;
  }
  .blog_list .blog_item:nth-of-type(2n) {
    margin: 0 0 15px !important;
  }
  .blog_list .img {
    height: 158px;
  }
}
</style>
